import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'

export const CandidatesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Candidates.mp4"
      videoPosterUrl="/intros/Intro%20-%20Candidates.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.title"
            defaultMessage="Add candidates"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text1"
            defaultMessage="It’s important to have all information about candidates when there are hundreds of candidates applied for a role. You can upload candidates in bulk during onboarding or add them one by one. You can also upload you existing candidates later, using “Import candidates” functionality in the candidate app."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.candidates.intro.text2"
          defaultMessage="The fields for the bulk upload are:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.name"
                  defaultMessage="candidate name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.email"
                  defaultMessage="email"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.role"
                  defaultMessage="role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.country"
                  defaultMessage="country"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.seniority"
                  defaultMessage="seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.recruiter"
                  defaultMessage="dedicated recruiter"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.posting"
                  defaultMessage="job posting"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.candidates.intro.text3"
          defaultMessage="All of that essential data is consolidated on the candidate profile, enabling you to track candidate’s progress and jump into action at every stage of the process."
        />
      </OnboardingIntroTextWidget>
    </OnboardingIntro>
  )
}
