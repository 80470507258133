import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React from 'react'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import { EntityTypes } from '@src/constants/api'

export const Goals = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <OrgEntityProvider
      entity={{
        type: EntityTypes.companyV2,
        data,
      }}
    >
      <GoalsTab />
    </OrgEntityProvider>
  )
}
