import { MoreBar } from '@revolut/ui-kit'
import { getProbationTemplates } from '@src/api/probationTemplate'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  probationManagersRulesColumn,
  probationTemplateCheckpointsColumn,
  probationTemplateNameColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
} from '@src/constants/columns/probation'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { TableWithForm } from './common/TableWithForm'

export const ProbationSettings = () => {
  const table = useTable<ProbationTemplateInterface>({ getItems: getProbationTemplates })
  const row: RowInterface<ProbationTemplateInterface> = {
    cells: [
      {
        ...probationTemplateNameColumn,
        width: 200,
      },
      {
        ...probationTemplateCheckpointsColumn,
        width: 50,
      },
      {
        ...probationTemplatePolicyColumn,
        width: 50,
      },
      {
        ...probationManagersRulesColumn,
        width: 150,
      },
      {
        ...probationTemplateStatusColumn,
        width: 80,
      },
    ],
  }
  return (
    <TableWithForm
      form={
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable Probation',
            description:
              'Allows newly onboarded employees to go trough a probation period',
          }}
          name="enable_probation"
        />
      }
      actions={
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={pathToUrl(
              ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PROBATION_TEMPLATE,
            )}
            useIcon="Plus"
          >
            Add new template
          </MoreBar.Action>
        </MoreBar>
      }
      table={
        <AdjustableTable
          name={TableNames.ProbationTemplates}
          row={row}
          noDataMessage="Probation templates will appear here."
          {...table}
        />
      }
    />
  )
}
