import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import {
  AllDocumentsStats,
  DocumentUploadRequestInterface,
  EmployeeDocumentInterface,
} from '@src/interfaces/documents'
import {
  getAllDocuments,
  getAllDocumentsStats,
  updateDocumentUploadInfo,
} from '@src/api/documents'
import { selectPermissions } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { PermissionTypes } from '@src/store/auth/types'
import { API, selectorKeys } from '@src/constants/api'
import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AddDocumentSidebar } from './AddDocumentSidebar'
import { existingItemsRow } from './row'

export const DocumentsList = () => {
  const permissions = useSelector(selectPermissions)
  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)

  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)

  const table = useTable<EmployeeDocumentInterface, AllDocumentsStats>({
    getItems: getAllDocuments,
    getStats: getAllDocumentsStats,
  })

  return (
    <>
      <GenericEditableTable
        apiEndpoint={`${API.EMPLOYEES}/documents`}
        tableName={TableNames.DocumentsOnboarding}
        row={existingItemsRow}
        entity="document"
        variant="existingEntities"
        getCustomEditCellAction={data =>
          updateDocumentUploadInfo(data.employee.id, data.recipient_id, {
            name: data.document_name,
            category: data.category,
            employee: data.employee,
          } as DocumentUploadRequestInterface)
        }
        tableActions={tableActionProps => (
          <MoreBar>
            {showDocumentButtons && (
              <>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => setIsAddNewSidebarOpen(!isAddNewSidebarOpen)}
                >
                  Add document
                </MoreBar.Action>
                <MoreBar.Action
                  to={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES)}
                  use={InternalLink}
                  useIcon="ShareIOs"
                >
                  Import documents
                </MoreBar.Action>
              </>
            )}
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Suitcase"
              field="category"
              selector={selectorKeys.document_categories}
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Profile"
              field="employee"
              label="assignee"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
          </MoreBar>
        )}
      />
      <AddDocumentSidebar
        isOpen={isAddNewSidebarOpen}
        onClose={() => setIsAddNewSidebarOpen(false)}
        onSubmit={() => {
          table.refresh()
          setIsAddNewSidebarOpen(false)
        }}
      />
    </>
  )
}
