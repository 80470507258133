import { IdAndName } from '@src/interfaces'
import { ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'
import { lape } from 'lape'

export interface OnboardingPreviewScorecardState {
  category: IdAndName<ReviewCategory>
  role?: IdAndName<number | string>
  seniority?: IdAndName<number | string>
  seenBy?: IdAndName<ReviewerRelation>
}

const defaultState: OnboardingPreviewScorecardState = {
  category: { id: ReviewCategory.Performance, name: 'Performance Review' },
  seenBy: { id: ReviewerRelation.Self, name: 'Self' },
}

export const previewState = lape(defaultState)
