import {
  Button,
  ButtonSkeleton,
  Cell,
  InputGroup,
  ItemSkeleton,
  VStack,
} from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import {
  performanceSettings,
  useGetPerformanceSettings,
} from '@src/api/performanceSettings'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { PageActions } from '@src/components/Page/PageActions'
import { InputSkeleton } from '@src/components/Skeletons/InputsSkeleton'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OptionInterface } from '@src/interfaces/selectors'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import { EditQuestion } from '@src/pages/Settings/Performance/Scorecards/EditQuestion'
import { pathToUrl } from '@src/utils/router'
import React, { useEffect, useState } from 'react'

const CustomQuestionsFormContent = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const [sectionIdx, setSectionIdx] = useState<number>()

  const { data: settings } = useGetPerformanceSettings()
  const showForFetchContext = useFetchOptions<OptionInterface>(
    selectorKeys.custom_scorecard_sections_show_options,
  )

  const showForOptions = settings
    ? showForFetchContext.options.filter(option => {
        return settings.enable_functional_management
          ? true
          : ![
              'performance_fm_scorecards',
              'probation_fm_scorecards',
              'pip_fm_scorecards',
            ].includes(String(option.value.id))
      })
    : []

  useEffect(() => {
    const newSection = {
      title: '',
      description: '',
      questions: [],
      show_for: null,
    }

    if (!values.custom_scorecard_sections) {
      values.custom_scorecard_sections = []
    }

    values.custom_scorecard_sections.push(newSection)
    setSectionIdx(values.custom_scorecard_sections.length - 1)
  }, [])

  const section =
    typeof sectionIdx === 'number' ? values.custom_scorecard_sections?.[sectionIdx] : null

  if (!section) {
    return null
  }

  const onAddQuestion = () => {
    section.questions.push({ question_type: null, options: [] })
  }

  const backUrl = pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD)

  return typeof sectionIdx === 'number' ? (
    <>
      <VStack>
        <CellWithItem
          icon="InfoOutline"
          title="Section info"
          description="Create the title, description and define visibility"
        >
          <InputGroup>
            <LapeNewInput
              name={`custom_scorecard_sections.${sectionIdx}.title`}
              label="Title"
              required
            />
            <LapeNewInput
              name={`custom_scorecard_sections.${sectionIdx}.description`}
              label="Description"
            />
            <LapeNewMultiSelect
              placeholder="Show for"
              name={`custom_scorecard_sections.${sectionIdx}.show_for`}
              required
              options={showForOptions}
            />
          </InputGroup>
        </CellWithItem>
        {section.questions.map((_, questionIdx) => (
          <EditQuestion
            key={questionIdx}
            sectionIdx={sectionIdx}
            questionIdx={questionIdx}
          />
        ))}
        <Button
          useIcon="Plus"
          variant="secondary"
          size="sm"
          onClick={onAddQuestion}
          mt="s-16"
          mb="s-40"
        >
          Add question
        </Button>
      </VStack>
      <PageActions>
        <Button variant="secondary" onClick={() => navigateReplace(backUrl)}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup useValidator afterSubmitUrl={backUrl}>
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  ) : null
}

export const CustomQuestionsForm = () => {
  return (
    <Form
      api={performanceSettings}
      forceParams={{ id: '1' }}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      loadingState={
        <VStack space="s-16">
          <Cell width="100%">
            <VStack space="s-16" width="100%">
              <ItemSkeleton />
              <InputGroup>
                <InputSkeleton />
                <InputSkeleton />
                <InputSkeleton />
              </InputGroup>
            </VStack>
          </Cell>
          <ButtonSkeleton />
        </VStack>
      }
    >
      <CustomQuestionsFormContent />
    </Form>
  )
}
