import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const JobsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Postings.mp4"
      videoPosterUrl="/intros/Intro%20-%20Postings.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.title"
            defaultMessage="Add your jobs"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text1"
            defaultMessage="Start your recruitment process with adding your jobs to the product. You can use bulk file import or manually create them in the system."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text2"
            defaultMessage="Every job has information about requisition (team for which you have an opening, role you want to hire for, and recruiter responsible) and job description that will be published on the job boards and your own career website provided by Revolut People."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text3"
            defaultMessage="Check and configure integrations with job boards and preview your job postings on careers website. You can publish them right away or keep them unpublished for the time being, having the ability to publish them at any point in the future."
          />
        </p>
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.tips.title"
            defaultMessage="Jobs setup tips"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.adding.title"
                defaultMessage="Adding jobs"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.adding.text"
                defaultMessage="Create jobs and specify essential job details. Opt in for bulk upload to speed up the process and import your existing postings. Review and check jobs information"
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.website.title"
                defaultMessage="Careers website and job boards"
              />
            ),
            icon: 'Browser',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.website.text"
                defaultMessage="Customise your career website, adjust your branding, add marketing content and contact links. When configured and published, careers website is your company’s personal job board with new jobs getting published automatically when your recruiters add them to the system. You can also opt in to publish your jobs on LinkedIn and Monster.com  job boards"
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.title"
                defaultMessage="Job postings"
              />
            ),
            icon: 'DocumentsPair',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.text"
                defaultMessage="Review jobs you have added. Publish ones that you want to advertise right now."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
