import {
  ActionButton,
  Box,
  Flex,
  VStack,
  Widget,
  Text,
  IconButton,
  Token,
  InputGroup,
  Button,
  Avatar,
  Badge,
  Dropdown,
  useDropdown,
} from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { cultureValuesRequestsNew } from '@src/api/cultureValues'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CultureValueInterface, CultureValueType } from '@src/interfaces/cultureValues'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import { pathToUrl } from '@src/utils/router'
import React, { MouseEvent, useRef } from 'react'

const CultureValuesFormContent = () => {
  const { values } = useLapeContext<CultureValueInterface>()
  const suggestionsDropdown = useDropdown()
  const inputRef = useRef<HTMLInputElement>(null)

  const suggestions = [
    {
      title: 'Never Settle',
      description:
        'We constantly push, rethink, and rework to get 10x further from where we are now. We aren’t afraid to be ambitious — and we’re always looking for the next big thing',
    },
    {
      title: 'Dream Team',
      description:
        'We believe the key to winning is building diverse, lean teams of brilliant go-getters who break down barriers',
    },
    {
      title: 'Think Deeper',
      description:
        "We believe logic, reason, and common sense prevail over everything else in decision-making. We dive deep until we get to atoms. If we don't know something – we bet, collect the data, and reiterate",
    },
    {
      title: 'Get It Done',
      description:
        'We believe that ideas are great, but execution is everything. That’s why respect comes from sweat and stretch',
    },
    {
      title: 'Deliver WOW',
      description:
        'We believe that everything we do should solve our customers’ needs. To create awe and inspire, we pay attention to every single detail',
    },
  ]

  const handleAddBehaviour = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    values.behaviours = [...(values.behaviours || []), { description: '', name: '' }]
  }

  const handleDeleteBehaviour = (id: number) => {
    values.behaviours = values.behaviours?.filter((_, i) => i !== id)
  }

  const backUrl = pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD)

  return (
    <>
      <VStack space="s-16" mb="s-16">
        <CellWithItem
          avatar={
            <Avatar>
              <Badge bg={Token.color.deepGrey}>1</Badge>
            </Avatar>
          }
          title="General info"
          description="Define the name, description and link to playbook if you have any"
        >
          <LapeNewInput
            {...suggestionsDropdown.getAnchorProps()}
            containerRef={inputRef}
            required
            name="name"
            label="Value Name"
            message="Type here to create your own or select values from our library"
          />
          <Dropdown
            {...suggestionsDropdown.getTargetProps()}
            open={values.name ? false : suggestionsDropdown.getTargetProps().open}
            focusTrap={false}
            anchorRef={inputRef}
            minWidth="auto"
            fitInAnchor
            closeOnEsc
          >
            {suggestions.map(({ title, description }) => (
              <Dropdown.Item
                use="button"
                key={title}
                onClick={() => {
                  values.name = title
                  values.description = description
                  // todo: prefill also behaviours once wording is provided (REVC-6085)
                  suggestionsDropdown.toggle()
                }}
              >
                <VStack
                  space="s-4"
                  maxWidth={
                    // Even though dropdown is instructed to fitInAnchor it still sets min-width: fit-content
                    // Means long content is rendered in one line, so I need to set max-width to prevent expanding outside boundaries
                    // - 32 is to compensate dropdown paddings
                    // slack thread in the ui-kit support channel: https://team-revolut.slack.com/archives/CDXMY0GF9/p1712779092236309
                    inputRef.current?.getBoundingClientRect().width
                      ? inputRef.current?.getBoundingClientRect().width - 32
                      : 'auto'
                  }
                >
                  <Text variant="secondary">{title}</Text>
                  <Text variant="small" color={Token.color.greyTone50} maxWidth="100%">
                    {description}
                  </Text>
                </VStack>
              </Dropdown.Item>
            ))}
          </Dropdown>
          <LapeNewTextArea
            rows={5}
            name="description"
            label="Description and definition of done"
            required
          />
        </CellWithItem>
        <CellWithItem
          avatar={
            <Avatar>
              <Badge bg={Token.color.deepGrey}>2</Badge>
            </Avatar>
          }
          title="Value based behaviours"
          description="Actions reflecting a company's principles"
          side={
            <ActionButton useIcon="Plus" onClick={handleAddBehaviour}>
              Add behaviour
            </ActionButton>
          }
        >
          {values.behaviours?.map((_, id) => {
            return (
              <Widget
                key={id}
                style={{ border: `1px solid ${Token.color.greyTone10}` }}
                data-testid="behaviour-form"
              >
                <Box p="s-16">
                  <VStack gap="s-16" mb="s-16">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text fontWeight={500} variant="primary">
                        Behaviour {id + 1}
                      </Text>
                      <IconButton
                        aria-label="Delete"
                        color={Token.color.greyTone20}
                        onClick={() => handleDeleteBehaviour(id)}
                        useIcon="Delete"
                      />
                    </Flex>
                    <InputGroup>
                      <LapeNewInput
                        name={`behaviours.${id}.name`}
                        label="Name"
                        required
                      />
                      <LapeNewTextArea
                        name={`behaviours.${id}.description`}
                        label="Description"
                        required
                        rows={5}
                      />
                    </InputGroup>
                  </VStack>
                </Box>
              </Widget>
            )
          })}
        </CellWithItem>
      </VStack>
      <PageActions>
        <Button variant="secondary" onClick={() => navigateReplace(backUrl)}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          useValidator
          disabled={!values.behaviours || values.behaviours.length === 0}
        />
      </PageActions>
    </>
  )
}

export const CultureValuesForm = () => {
  return (
    <Form
      api={cultureValuesRequestsNew}
      initialValues={{
        category: CultureValueType.Culture,
        is_enabled: true,
      }}
    >
      <CultureValuesFormContent />
    </Form>
  )
}
