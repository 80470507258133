import React from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { BulkEditAction } from '@src/features/GenericEditableTable/components'
import { bulkUpdateDocuments } from '@src/api/importData'
import { updateDocumentBulkSessionUploadInfo } from '@src/api/documents'
import { bulkSessionRow } from './row'

export const DocumentsSession = () => {
  const formatBulkData = (
    rowsData: { id: number }[] | undefined,
    field: 'category' | 'employee',
    optionId: string | number | undefined,
  ) =>
    rowsData
      ?.map(rowData =>
        typeof optionId === 'number'
          ? {
              id: rowData.id,
              [field]: { id: optionId },
            }
          : null,
      )
      .filter(Boolean) || []

  return (
    <BulkDataUploadV2
      importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.ANY}
      apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
      category="document_upload"
      name="document"
      tableName={TableNames.ImportDocumentsV2}
      row={bulkSessionRow}
      entity="document"
      templateParams={{ version: '2' }}
      getCustomEditCellAction={(data, rowId, sessionId) => {
        return updateDocumentBulkSessionUploadInfo(sessionId!, rowId, data)
      }}
      header={
        <PageHeader
          title="Upload documents"
          backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST)}
        />
      }
      tableActions={tableActionsProps => (
        <MoreBar>
          <BulkEditAction
            buttonIcon="Suitcase"
            field="category"
            selector={selectorKeys.document_categories}
            {...tableActionsProps}
            getCustomApiCall={(sessionId, selectedRowsData, option) => {
              return () =>
                bulkUpdateDocuments(
                  String(sessionId),
                  formatBulkData(selectedRowsData, 'category', option?.id),
                )
            }}
          />
          <BulkEditAction
            buttonIcon="Profile"
            field="employee"
            label="assignee"
            selector={selectorKeys.all_employees_avatar_email}
            {...tableActionsProps}
            getCustomApiCall={(sessionId, selectedRowsData, option) => {
              return () =>
                bulkUpdateDocuments(
                  String(sessionId),
                  formatBulkData(selectedRowsData, 'employee', option?.id),
                )
            }}
          />
        </MoreBar>
      )}
    />
  )
}
