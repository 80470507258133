import React from 'react'
import groupBy from 'lodash/groupBy'
import { Item, VStack, Text, Token, TextButton, Subheader, Cell } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { useGetConfigurableBulkUploadFields } from '@src/api/bulkDataImport'
import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'
import { getAddFieldButtonProps } from './helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  onAddField: (field: BulkUploadConfigurableField) => void
  onRemoveField: (field: BulkUploadConfigurableField) => void
  addedFields: BulkUploadConfigurableField[]
  name: string
  mandatoryColumns: string[]
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  onAddField,
  onRemoveField,
  addedFields,
  name,
  mandatoryColumns,
}: Props) => {
  const { data: configurableFieldsData = [] } = useGetConfigurableBulkUploadFields(
    name === 'contract' ? 'contracts' : name === 'goals' ? 'goals' : 'employees',
  )

  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData,
    field => field.category,
  )
  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      <VStack space="s-16">
        {Object.entries(groupedFieldsData).map(([group, fields]) => (
          <React.Fragment key={group}>
            <Subheader variant="nested">
              <Subheader.Title>{group}</Subheader.Title>
            </Subheader>
            <Cell p={0} mt="-s-16">
              <VStack width="100%">
                {fields.map(field => (
                  <Item key={field.name}>
                    <Item.Content>{field.name}</Item.Content>
                    <Item.Side>
                      {field.is_required ||
                      mandatoryColumns.includes(field.column_name) ? (
                        <Text
                          color={Token.color.greyTone50}
                          aria-label={`${field.name} preset`}
                        >
                          Preset
                        </Text>
                      ) : addedFields.find(f => f.name === field.name) ? (
                        <TextButton
                          onClick={() => onRemoveField(field)}
                          aria-label={`Remove ${field.name}`}
                        >
                          Remove
                        </TextButton>
                      ) : (
                        <TextButton
                          onClick={() => onAddField(field)}
                          {...getAddFieldButtonProps(name, field, addedFields)}
                          aria-label={`Add ${field.name}`}
                        >
                          Add
                        </TextButton>
                      )}
                    </Item.Side>
                  </Item>
                ))}
              </VStack>
            </Cell>
          </React.Fragment>
        ))}
      </VStack>
    </SideBar>
  )
}
