import React, { useState } from 'react'
import { Button, Header, Popup, Subheader } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { COPY_FROM_POLICY_ID } from '@src/pages/Forms/TimeOffPolicies/EditTabs/common'
import { pathToUrl } from '@src/utils/router'

type PolicySource = 'new' | 'template' | null

interface PopupProps {
  open: boolean
  onClose: () => void
}

export const AddNewPolicyPopup = ({ open, onClose }: PopupProps) => {
  const [copyFromPolicy, setCopyFromPolicy] = useState<IdAndName>()
  const [newPolicyType, setNewPolicyType] = useState<PolicySource>('new')

  return (
    <Popup open={open} onClose={onClose}>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>New time off policy</Header.Title>
        <Header.Description>How do you want to start your policy?</Header.Description>
      </Header>
      <RadioSwitch<PolicySource>
        value={newPolicyType}
        options={[
          {
            id: 'new',
            label: 'Start from scratch',
            description:
              'You can configure all the policy settings in the following steps.',
            value: 'new',
          },
          {
            id: 'template',
            label: 'Copy from an existing policy',
            description:
              'Choose a policy that you want to duplicate and modify in the following steps.',
            value: 'template',
          },
        ]}
        onChange={val => {
          if (val === 'new') {
            setCopyFromPolicy(undefined)
          }
          setNewPolicyType(val)
        }}
      />
      {newPolicyType === 'template' && (
        <>
          <Subheader variant="nested">From which policy do you want to copy?</Subheader>
          <RadioSelectInput
            selector={selectorKeys.time_off_policies}
            label="Choose a policy"
            value={copyFromPolicy}
            onChange={val => setCopyFromPolicy(val || undefined)}
          />
        </>
      )}
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          elevated
          onClick={() => {
            navigateTo(
              pathToUrl(
                ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS,
                undefined,
                copyFromPolicy
                  ? { [COPY_FROM_POLICY_ID]: String(copyFromPolicy.id) }
                  : undefined,
              ),
            )
          }}
          disabled={newPolicyType === 'template' && !copyFromPolicy}
        >
          Start
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
