import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { RequestInterfaceNew } from '@src/interfaces'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SettingsLanding } from './Landing'
import { CycleSettings } from './Cycle'
import { ReviewTypeSettings } from './ReviewTypes'
import { PipSettings } from './PIP'
import { ProbationSettings } from './Probation'
import { InnerRoute } from '../common/InnerRoute'
import { API } from '@src/constants/api'
import { OnboardingProbationTemplateForm } from './ProbationTemplateForm'
import { InnerSettingsRoute } from './common/InnerSettingsRoute'

export const Settings = <T extends {}>({
  api,
  loadingState,
}: PropsWithChildren<{ api: RequestInterfaceNew<T>; loadingState?: ReactNode }>) => {
  return (
    <Form
      api={api}
      loadingState={loadingState}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
    >
      <Switch>
        <InnerSettingsRoute
          path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.GENERAL}
          title="Review settings"
          description="Settings for performance functionalities"
          backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD}
          showActions={false}
        >
          <SettingsLanding />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.CYCLE}
          title="Cycle duration"
          backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.GENERAL}
        >
          <CycleSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.REVIEWS}
          title="Review types"
          backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.GENERAL}
        >
          <ReviewTypeSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PIP}
          title="PIP settings"
          backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.GENERAL}
        >
          <PipSettings />
        </InnerSettingsRoute>

        <InnerSettingsRoute
          path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PROBATION}
          title="Probation settings"
          backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.GENERAL}
        >
          <ProbationSettings />
        </InnerSettingsRoute>

        <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PROBATION_TEMPLATE}>
          <InnerRoute
            title="New Probation Template"
            description="Create a new probation cycle template"
            backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PROBATION}
          >
            <OnboardingProbationTemplateForm />
          </InnerRoute>
        </Route>
      </Switch>
    </Form>
  )
}
