import React, { useState } from 'react'

import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import {
  BulkDeleteButton,
  BulkEditAction,
  GenericEditableTableCreateCallbackType,
} from '@src/features/GenericEditableTable/components'
import { ImportCandidatesDataInterface } from '@src/interfaces/importCandidates'
import {
  importCandidatesV2CountryColumn,
  importCandidatesV2EmailColumn,
  importCandidatesV2JobTitleColumn,
  importCandidatesV2LinkedinUrlColumn,
  importCandidatesV2NameColumn,
  importCandidatesV2RecruiterColumn,
  importCandidatesV2RoleColumn,
  importCandidatesV2SeniorityColumn,
} from '@src/constants/columns/importCandidatesV2'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { pathToUrl } from '@src/utils/router'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { omit } from 'lodash'
import { useParams } from 'react-router-dom'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { IdAndName } from '@src/interfaces'

type CreateNewItemType = 'role'

const row =
  (onCreate: GenericEditableTableCreateCallbackType<CreateNewItemType>) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<ImportCandidatesDataInterface>> => ({
    cells: [
      {
        ...importCandidatesV2NameColumn(options.onChange),
        width: 200,
      },
      {
        ...importCandidatesV2EmailColumn(options.onChange),
        width: 150,
      },
      {
        ...importCandidatesV2LinkedinUrlColumn(options.onChange),
        width: 150,
      },
      {
        ...importCandidatesV2RoleColumn(options.onChange, (id, onChangeAction) => {
          onCreate('role', id, onChangeAction)
        }),
        width: 150,
      },
      {
        ...importCandidatesV2CountryColumn(options.onChange),
        width: 150,
      },
      {
        ...importCandidatesV2SeniorityColumn(options.onChange),
        width: 150,
      },
      {
        ...importCandidatesV2RecruiterColumn(options.onChange),
        width: 200,
      },
      {
        ...importCandidatesV2JobTitleColumn(options.onChange),
        width: 200,
      },
    ],
  })

const hiddenColumnsBase = {
  team: true,
  specialisation: true,
  seniority: true,
  country: true,
  recruiter: true,
  job_posting: true,
  linkedin_url: true,
}

const hiddenColumns: { [k in keyof typeof hiddenColumnsBase]: Record<string, boolean> } =
  {
    team: omit(hiddenColumnsBase, ['team']),
    specialisation: omit(hiddenColumnsBase, ['specialisation', 'seniority']),
    seniority: omit(hiddenColumnsBase, ['specialisation', 'seniority']),
    country: omit(hiddenColumnsBase, ['country']),
    recruiter: omit(hiddenColumnsBase, ['recruiter']),
    job_posting: omit(hiddenColumnsBase, ['job_posting']),
    linkedin_url: omit(hiddenColumnsBase, ['linkedin_url']),
  }

type CandidateFields = keyof typeof hiddenColumns

export const CandidatesFlow = () => {
  const params = useParams<{ type?: CandidateFields }>()

  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    id: number
    onChangeAction: (newEntity: IdAndName) => void
  }>()

  const onCreateNew: GenericEditableTableCreateCallbackType<CreateNewItemType> = (
    type,
    id,
    onChangeAction,
  ) => {
    setCreateNewTypeState({ type, id, onChangeAction })
  }

  return (
    <>
      <BulkDataUploadV2
        importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.IMPORT}
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.SESSION}
        anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.ANY}
        apiEndpoint={API.CANDIDATES_UPLOAD}
        category="candidate_bulk_upload"
        name="candidate"
        tableName={TableNames.ImportCandidatesV2}
        hiddenColumns={params?.type ? hiddenColumns[params.type] : {}}
        row={row(onCreateNew)}
        entity="candidate"
        templateParams={{
          version: '2',
          extra_columns:
            'full_name,email,specialisation,country,seniority,recruiter,job_posting',
        }}
        header={
          <OnboardingChecklistHeader
            title="Import candidates"
            backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.IMPORT)}
          />
        }
        tableActions={props => (
          <MoreBar>
            <BulkEditAction
              buttonIcon="RepairTool"
              field="specialisation"
              selector={selectorKeys.specialisations}
              {...props}
              apiEndpoint={API.CANDIDATES_UPLOAD}
              label="role"
            />
            <BulkEditAction
              buttonIcon="MapPoint"
              field="country"
              selector={selectorKeys.countries}
              {...props}
              apiEndpoint={API.CANDIDATES_UPLOAD}
            />
            <BulkEditAction
              buttonIcon="ArrowRightLeft"
              field="seniority"
              selector={selectorKeys.seniority}
              {...props}
              apiEndpoint={API.CANDIDATES_UPLOAD}
            />
            <BulkEditAction
              buttonIcon="Profile"
              field="recruiter"
              selectorField="email"
              selector={selectorKeys.all_employees_avatar_email}
              {...props}
              apiEndpoint={API.CANDIDATES_UPLOAD}
            />
            <BulkEditAction
              buttonIcon="Document"
              field="job_posting"
              selector={selectorKeys.job_postings}
              {...props}
              apiEndpoint={API.CANDIDATES_UPLOAD}
              label="job posting"
            />
            <BulkDeleteButton {...props} />
          </MoreBar>
        )}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
