import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React, { useEffect, useState } from 'react'
import { GoalForm } from './Form/GoalForm'
import { PageActions } from '@src/components/Page/PageActions'
import { ActionButton, BREAKPOINTS, Button, Flex, Spinner } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLocation } from 'react-router-dom'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { PageBody } from '@src/components/Page/PageBody'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from './common/utils'
import { deleteGoal } from '@src/api/goals'
import { goBack, navigateReplace, navigateTo } from '@src/actions/RouterActions'
import {
  FormObserverProvider,
  useFormObserver,
} from './Form/Widgets/FormObserverProvider'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useGoalFormSubmit } from './Form/useGoalFormSubmit'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

const getCache = (key: string): Partial<GoalsInterface> | null => {
  try {
    const rawCache = localStorage.getItem(key)
    return rawCache ? JSON.parse(rawCache) : null
  } catch (err) {
    captureException(err)
    return null
  }
}

const setCache = (key: string, value: Partial<GoalsInterface>): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    captureException(err)
  }
}

export const useGoalFormCache = () => {
  const { values, initialValues } = useLapeContext<GoalsInterface>()
  const [cacheUpdated, setCacheUpdated] = useState(false)

  const lsKey = `goal-${values.id}-cache`
  const cache = getCache(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalsInterface but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
    setCacheUpdated(true)
  }, [])

  useEffect(() => {
    if (
      values.name !== initialValues.name ||
      values.description !== initialValues.description ||
      values.owner?.id !== initialValues.owner?.id ||
      values.update_type?.id !== initialValues.update_type?.id ||
      values.content_object?.id !== initialValues.content_object?.id ||
      values.parent?.id !== initialValues.parent?.id
    ) {
      setCache(lsKey, {
        name: values.name,
        description: values.description,
        owner: values.owner ? { id: values.owner.id } : undefined,
        parent: values.parent ? ({ id: values.parent.id } as GoalsInterface) : null,
        update_type: values.update_type
          ? { id: values.update_type.id, name: values.update_type.name }
          : undefined,
        content_object: values.content_object,
      })
    }
  }, [
    values.name,
    values.description,
    values.owner?.id,
    values.parent?.id,
    values.update_type?.id,
    values.content_object?.id,
    // todo: add cache for new target fields
  ])

  return {
    cleanCache: () => localStorage.removeItem(lsKey),
    cacheUpdated,
  }
}

const GoalFormPageBody = ({
  performanceSettings,
  defaultReviewCycle,
  onSubmitted,
  onDirtyChanged,
}: {
  performanceSettings: PerformanceSettingsInterface
  defaultReviewCycle?: ReviewCyclesInterface
  onSubmitted: () => void
  onDirtyChanged: (isDirty: boolean) => void
}) => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, dirty } = goalForm
  const { hasDirtyForms } = useFormObserver()
  const { cacheUpdated } = useGoalFormCache()
  const { submit } = useGoalFormSubmit()

  const isDraft = values.status.id === Statuses.draft

  const isDirty = hasDirtyForms() || dirty

  useEffect(() => {
    onDirtyChanged(isDirty)
  }, [isDirty])

  // eslint-disable-next-line consistent-return
  const onSubmit = async (updateStatus: boolean) => {
    try {
      await submit(updateStatus)
      onSubmitted()
    } catch (err) {
      // that's ok, validation error
    }
  }

  return (
    <PageBody maxWidth={{ all: BREAKPOINTS.lg, xxl: BREAKPOINTS.xl }}>
      {cacheUpdated && (
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={defaultReviewCycle}
        />
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDraft
            ? isDirty && (
                <Button variant="secondary" onClick={() => onSubmit(false)}>
                  Save as draft
                </Button>
              )
            : null}
          {isDirty && (
            <Button onClick={() => onSubmit(true)} elevated>
              {isDraft ? 'Submit' : 'Save changes'}
            </Button>
          )}
        </Flex>
      </PageActions>
    </PageBody>
  )
}

export const GoalFormPage = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const [autoDeletePending, setAutoDeletePending] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    isNew?: boolean
  }>()
  const { confirm, prompt, showError, showLoading, confirmationDialog } =
    useSubmitFlowHelpers()
  const { submit } = useGoalFormSubmit()
  const isOnboarding = isOnboardingPath()

  const isDraft = values.status.id === Statuses.draft
  const isNew = !!location.state?.isNew

  const shouldAutoDelete = isDraft && isNew
  const shouldShowDelete = isDraft && !isNew
  const shouldConfirmBack = isDraft && isNew && isDirty

  const backRoutes: Record<'employees' | 'teams' | 'department', string> = {
    employees: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
    teams: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,
  }

  const backUrl = values.content_type?.model
    ? pathToUrl(backRoutes[values.content_type?.model], {
        id: values.object_id,
      })
    : isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
    : ROUTES.FORMS.COMPANY.GOALS.GENERAL

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.companyV2]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return location.state?.reviewCycleId
      ? cycle.id === Number(location.state.reviewCycleId)
      : cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'company'

  const title = isNew
    ? `Add new ${entityTitle} goal`
    : isDraft
    ? `Edit ${entityTitle} draft goal`
    : `Edit ${entityTitle} goal`

  const deleteAndGoBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    setAutoDeletePending(false)
    goBack(backUrl)
  }

  const confirmBack = async () => {
    const confirmVariant = values.name ? confirm : prompt

    const confirmed = await confirmVariant({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      label: 'You have unsaved changes',
      body: 'Do you want to save them or delete before proceeding?',
      promptLabel: 'Please set goal name before saving',
      commentRequired: true,
    })

    if (confirmed.status === 'canceled') {
      return
    }

    if (confirmed.status === 'confirmed') {
      if (confirmed.comment) {
        values.name = confirmed.comment
      }
      await submit(false)
      goBack(backUrl)
    }

    if (confirmed.status === 'rejected') {
      deleteAndGoBack()
    }
  }

  const handleBack = () => {
    if (shouldConfirmBack) {
      confirmBack()
    } else if (shouldAutoDelete) {
      deleteAndGoBack()
    } else {
      goBack(backUrl)
    }
  }

  const onDeleteGoal = async () => {
    const confirmed = await confirm({
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
      body: 'Do you want to delete this goal?',
    })

    const navigateBack = () => {
      const history: string[] | undefined = location.state?.history
      const isLastWasPreview = history?.at(-1)?.match(RegExp(`/goal/.*/${values.id}.*`))

      if (isLastWasPreview) {
        navigateReplace(backUrl)
      } else {
        goBack(backUrl, undefined, true)
      }
    }

    if (confirmed.status === 'confirmed') {
      try {
        const hidePopup = showLoading('Deleting...')
        await deleteGoal(values.id)
        hidePopup()
        navigateBack()
      } catch (err) {
        captureException(err)
        showError('Failed to delete goal', 'Please try again')
      }
    }
  }

  const onSubmitted = () => {
    if (values.status.id === Statuses.draft) {
      goBack(backUrl)
    } else {
      navigateTo(
        pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
            : ROUTES.FORMS.GOAL.PREVIEW,
          { id: values.id },
        ),
      )
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        onClickBack={handleBack}
        subtitle={
          shouldShowDelete && (
            <ActionButton useIcon="Delete" variant="negative" onClick={onDeleteGoal}>
              Delete
            </ActionButton>
          )
        }
        hideGlobalSearch={isOnboarding}
      />
      <FormObserverProvider>
        <GoalFormPageBody
          onDirtyChanged={setIsDirty}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
          onSubmitted={onSubmitted}
          performanceSettings={performanceSettings}
        />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}
