import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { roadmapsExtraRequests } from '@src/api/roadmaps'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { TableWidget, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { useShowRoadmapDetails, useRoadmapContext, useRoadmapRefresh } from './RoadmapTab'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
      width: 350,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    {
      ...roadmapProgressColumn,
      width: 120,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapStartDate,
      width: 100,
    },
    {
      ...roadmapDueDate,
      width: 100,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 100,
    },
    {
      ...ownerNameColumn,
      width: 120,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const CompanyRoadmaps = ({
  initialCycle,
}: {
  initialCycle: ReviewCyclesInterface
}) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled

  const initialFilter = [
    ...getCommonFilters({ reviewCycle: initialCycle }),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(roadmapsExtraRequests, initialFilter)

  const { renderCount } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
          />
          <OverallProgress value={table?.stats?.avg_done} />
        </TableWidget.Info>
        <TableWidget.Actions>
          <MoreBar>
            <SettingsButton
              path={ROUTES.SETTINGS.GOALS.ALL}
              canView={[
                PermissionTypes.ViewPerformancePreferences,
                PermissionTypes.ChangePerformancePreferences,
              ]}
            />
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <AdjustableTable<RoadmapInterface>
            name={TableNames.CompanyRoadmap}
            useWindowScroll
            row={ROW}
            onRowClick={showRoadmapDetails}
            {...table}
            dataType={ctx.dataType}
            pendingDataType={ctx.isLoading}
            noDataMessage={ctx.noDataMessage}
            expandableType="chevron"
            fetchChildren={(parentIndexes, id) => table.fetchChildren(parentIndexes, id)}
            renderCount={
              table?.stats?.refresh_date_time
                ? count => renderCount(count, table?.stats?.refresh_date_time!)
                : undefined
            }
          />
        </TableWidget.Table>
      </TableWidget>
      {renderSidebar({ canEdit: false })}
    </>
  )
}
