import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import React from 'react'
import { TableWithForm } from './common/TableWithForm'

export const PipSettings = () => {
  return (
    <TableWithForm
      form={
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable PIP',
            description:
              'Allows putting employees who are not performing well on a performance improvement plan',
          }}
          name="enable_pip"
        />
      }
    />
  )
}
