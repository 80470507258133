import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import {
  onboardingTimeManagementImportBalanceBalanceColumn,
  onboardingTimeManagementImportBalanceEmployeeColumn,
  onboardingTimeManagementImportBalancePolicyColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportTimeOffBalancesInterface } from '@src/interfaces/importTimeOffBalances'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportTimeOffBalancesInterface>> => ({
  cells: [
    {
      ...onboardingTimeManagementImportBalanceEmployeeColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportBalancePolicyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportBalanceBalanceColumn(options.onChange),
      width: 200,
    },
  ],
})

export const BalanceImportFlow = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.TIME_OFF_BALANCES_BULK_UPLOAD}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.ANY}
      importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.IMPORT}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.SESSION}
      category="time_off_balance_bulk_upload"
      entity="balance"
      name="balance"
      row={row}
      tableName={TableNames.UploadTimeOffProjectedBalances}
      uploadParams={{ is_projected: true }}
      header={
        <OnboardingChecklistHeader
          backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ASSIGNMENTS)}
          title="Simple balance adjustments"
        />
      }
      tableActions={props => (
        <MoreBar>
          <BulkEditAction
            {...props}
            apiEndpoint={API.TIME_OFF_BALANCES_BULK_UPLOAD}
            buttonIcon="ArrowRightLeft"
            field="policy"
            selector={selectorKeys.time_off_policies}
          />
          <BulkDeleteButton {...props} />
        </MoreBar>
      )}
    />
  )
}
