import React from 'react'
import { Flex, Box, Token } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Switch, Route } from 'react-router-dom'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import { Subtab } from '@src/interfaces/tabPinning'
import { usePermissionChecks } from '@components/PageWithTabs/usePermissionChecks'

interface PageWithTabsProps {
  tabs: Subtab[]
}

export const PageWithTabs = ({ tabs }: PageWithTabsProps) => {
  const { checkPermissions, isLoading } = usePermissionChecks()

  if (isLoading) {
    return null
  }

  const filteredTabs = tabs.filter(tab => checkPermissions(tab))

  if (filteredTabs.length === 0) {
    return null
  }

  return (
    <Box backgroundColor={Token.color.widgetBackground} radius="widget" pt="s-16">
      <Flex flexDirection="column" width="100%">
        {filteredTabs.length > 1 ? (
          <Flex alignItems="flex-start" mb="s-8" px="s-16">
            <TabBarNavigation isSubtab tabs={filteredTabs} />
          </Flex>
        ) : null}
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
          {filteredTabs[0].path && <InternalRedirect to={filteredTabs[0].path} />}
        </Switch>
      </Flex>
    </Box>
  )
}
