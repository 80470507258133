import React from 'react'
import { connect } from 'lape'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace } from '@src/actions/RouterActions'
import { Button } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const OnboardingActions = connect(({ backPath }: { backPath: string }) => {
  return (
    <PageActions>
      <Button variant="secondary" onClick={() => navigateReplace(backPath)}>
        Cancel
      </Button>
      <NewSaveButtonWithPopup useValidator afterSubmitUrl={backPath} />
    </PageActions>
  )
})
