import React, { RefObject, useRef, useState } from 'react'
import {
  FinalGrade,
  ManagerRecommendationInterface,
  ReviewDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Token,
  useTooltip,
  Tooltip as UIKitTooltip,
  VStack,
  Widget,
  MoreBar,
} from '@revolut/ui-kit'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'
import { RecommendationGradeRevamped } from '@src/pages/Forms/EmployeePerformance/components/RecommendationGradeRevamped'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { RecommendedGradesInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { PerformanceEditSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/PerformanceEditSummary'
import SideBar from '@components/SideBar/SideBar'

interface Props {
  isViewMode?: boolean
  recommendationData?: ManagerRecommendationInterface
  renderJustification: () => React.ReactNode
  renderError?: () => React.ReactNode | null
  onRadioChange?: (value: FinalGrade) => void
  onFeedbackClick?: () => void
  isSelfReview: boolean
  reviews?: ManagerRecommendationInterface[]
  feedbackContent: React.ReactNode
  recommendationRef?: RefObject<HTMLDivElement>
  reviewData?: ReviewDataInterface
}

const GradeRatingWithTooltip = ({
  option,
  isViewMode,
  items,
  gradesMap,
  recommendedGrades,
}: {
  gradesMap: GradesMapInterface
  option: { key: FinalGrade; items: string[] }
  isViewMode: boolean
  items?: ManagerRecommendationInterface[]
  recommendedGrades: RecommendedGradesInterface
}) => {
  const ratingTooltip = useTooltip()

  return (
    <Flex gap="s-8" {...ratingTooltip.getAnchorProps()} width="fit-content">
      <RecommendationGradeRevamped
        gradesMap={gradesMap}
        recommendedGrades={recommendedGrades}
        value={option.key}
        fontWeight={500}
        variant="primary"
        pb="s-8"
      />
      {isViewMode && !!items?.length && (
        <>
          <Badge
            minWidth="s-20"
            height="s-16"
            mt="s-2"
            backgroundColor={Token.color.deepGrey}
            style={{ fontWeight: 500 }}
          >
            {items.length}
            <UIKitTooltip
              {...ratingTooltip.getTargetProps()}
              maxWidth={200}
              placement="right-start"
            >
              <VStack>
                <Text variant="small" fontWeight="bold">
                  {gradesMap[option.key]}
                </Text>
                {items.map(item => (
                  <Text key={item.reviewer?.id} variant="small">
                    {item.reviewer?.full_name}
                  </Text>
                ))}
              </VStack>
            </UIKitTooltip>
          </Badge>
        </>
      )}
    </Flex>
  )
}

export const FeedbackCommon = connect(
  ({
    isViewMode = false,
    recommendationData,
    reviews,
    renderError,
    renderJustification,
    onRadioChange,
    isSelfReview,
    feedbackContent,
    recommendationRef,
    reviewData,
  }: Props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const tooltipAnchor = useRef<HTMLDivElement[]>([])
    const performanceOptions = recommendationData?.employee_project_performance.options
    const gradesMap = useGetReviewGradesMap()
    const recommendedGrades = useRecommendedGradesContext()

    return (
      <Widget>
        <Flex alignItems="center" justifyContent="space-between" p="s-16">
          <Flex alignItems="center" gap="s-16" width="30%">
            <Avatar useIcon="40/SubtractStar" />
            <Text variant="primary" fontWeight={400}>
              Feedback
            </Text>
          </Flex>
        </Flex>
        {!isViewMode && (
          <Box ml="s-16" mb="s-12">
            <MoreBar>
              <MoreBar.Action onClick={() => setSidebarOpen(!sidebarOpen)}>
                View summary details
              </MoreBar.Action>
            </MoreBar>
            <SideBar
              variant="wide"
              isOpen={sidebarOpen}
              onClose={() => setSidebarOpen(false)}
            >
              <PerformanceEditSummary reviewData={reviewData} />
            </SideBar>
          </Box>
        )}
        <Box p="s-16">
          <VStack space="s-16">
            {recommendationData && (
              <>
                <Text variant="primary" fontWeight={600} ref={recommendationRef}>
                  {recommendationData.employee_project_performance.name}
                </Text>
                {performanceOptions?.map((option, optionIdx) => {
                  const items = reviews?.filter(
                    item => item.employee_project_performance.value === option.key,
                  )
                  const checked = isViewMode
                    ? !!items?.length
                    : recommendationData.employee_project_performance.value === option.key
                  return (
                    <Box
                      key={optionIdx}
                      px="s-16"
                      mb="s-16"
                      data-testid={`feedback-${option.key}`}
                      borderRadius={Token.radius.r12}
                      bg={!isViewMode && checked ? Token.color.blue_5 : undefined}
                    >
                      <Flex>
                        <RadioButton
                          label={
                            <div>
                              <Box p="s-12">
                                <GradeRatingWithTooltip
                                  option={option}
                                  isViewMode={isViewMode}
                                  items={items}
                                  gradesMap={gradesMap}
                                  recommendedGrades={recommendedGrades}
                                />
                                <ul
                                  style={{
                                    paddingInlineStart: 16,
                                    margin: 0,
                                    color: Token.color.greyTone50,
                                  }}
                                >
                                  {option.items.map((item, i) => (
                                    <li key={i}>
                                      <Text
                                        variant="caption"
                                        color={Token.color.greyTone50}
                                      >
                                        {item}
                                      </Text>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </div>
                          }
                          checked={checked}
                          disabled={isViewMode}
                          onChange={() => onRadioChange?.(option.key)}
                          inputRef={el => {
                            tooltipAnchor.current[optionIdx] = el
                          }}
                          alignTop
                          capitalize={false}
                        />
                      </Flex>
                    </Box>
                  )
                })}
                {renderError?.()}
                {renderJustification()}
              </>
            )}
            <VStack mt="s-8">
              {!isSelfReview && (
                <Text variant="primary" fontWeight={600} mb={isViewMode ? '-s-8' : 's-8'}>
                  What feedback do you want to provide?
                </Text>
              )}
              {feedbackContent}
            </VStack>
          </VStack>
        </Box>
      </Widget>
    )
  },
)
