import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { jobPostingSettings, useGlobalSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { CareersIntegrations } from '@src/pages/Settings/JobPosting/CareerPage/CareersIntegrations'

const ListPage = () => {
  const { settings } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const canViewApplicationFormSections = permissions.includes(
    PermissionTypes.ViewApplicationFormPreferences,
  )
  const canViewCareerPagePreferences = permissions.includes(
    PermissionTypes.ViewCareerPagePreferences,
  )
  return (
    <>
      <PageBody mb="s-64">
        <Group mb="s-24">
          <NewSelectionCard
            icon="Megaphone"
            title="Job posting settings"
            to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.GENERAL)}
            subtitle={
              <FormattedMessage
                id="settings.jobPostings.general.description"
                defaultMessage="Basic settings for all job postings"
              />
            }
            use={InternalLink}
          />
          {canViewApplicationFormSections && (
            <NewSelectionCard
              icon="Questionnaire"
              title="Application forms"
              to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS)}
              subtitle={
                <FormattedMessage
                  id="settings.jobPostings.applicationForms.description"
                  defaultMessage="Configure application form questions"
                />
              }
              use={InternalLink}
            />
          )}
          {settings.career_page_enabled && canViewCareerPagePreferences && (
            <NewSelectionCard
              icon="Browser"
              title="Careers website settings"
              to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE)}
              subtitle={
                <FormattedMessage
                  id="settings.jobPostings.careersWebsite.description"
                  defaultMessage="Publish and manage your careers website"
                />
              }
              use={InternalLink}
            />
          )}
          <NewSelectionCard
            icon="CheckVerification"
            title="Approval settings"
            to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.APPROVAL)}
            subtitle={
              <FormattedMessage
                id="settings.jobPostings.approval.description"
                defaultMessage="Enable and customise job postings approval flow"
              />
            }
            use={InternalLink}
          />
        </Group>
        <CareersIntegrations />
      </PageBody>
    </>
  )
}

const routes = [
  {
    title: 'Job postings',
    path: ROUTES.SETTINGS.JOB_POSTING.LIST,
    url: ROUTES.SETTINGS.JOB_POSTING.LIST,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: ListPage,
  },
]

export const List = () => {
  return <SettingsForm routes={routes} api={jobPostingSettings} />
}
