import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { requisitionSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

const ListPage = () => {
  const permissions = useSelector(selectPermissions)
  const canViewHiringCountries = permissions.includes(
    PermissionTypes.ViewHiringProcessPreferences,
  )
  return (
    <PageBody mb="s-64">
      <Group mb="s-24">
        <NewSelectionCard
          icon="AddContact"
          title="Requisition settings"
          to={pathToUrl(ROUTES.SETTINGS.REQUISITIONS.GENERAL)}
          subtitle={
            <FormattedMessage
              id="settings.recruitment.general.description"
              defaultMessage="Basic settings for all requisitions"
            />
          }
          use={InternalLink}
        />
        {canViewHiringCountries && (
          <NewSelectionCard
            icon="Globe"
            title="Hiring countries settings"
            to={pathToUrl(ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING)}
            subtitle={
              <FormattedMessage
                id="settings.recruitment.hiringCountries.description"
                defaultMessage="Define which locations can be added to a requisition"
              />
            }
            use={InternalLink}
          />
        )}
        <NewSelectionCard
          icon="CheckVerification"
          title="Approval settings"
          to={pathToUrl(ROUTES.SETTINGS.REQUISITIONS.APPROVAL)}
          subtitle={
            <FormattedMessage
              id="settings.recruitment.approval.description"
              defaultMessage="Enable and customise requisitions approval flow"
            />
          }
          use={InternalLink}
        />
      </Group>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Requisition settings',
    path: ROUTES.SETTINGS.REQUISITIONS.LIST,
    url: ROUTES.SETTINGS.REQUISITIONS.LIST,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: ListPage,
  },
]

export const List = () => {
  return <SettingsForm routes={routes} api={requisitionSettings} />
}
