import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  deliverablesRating,
  getKpiGrades,
  CommonGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import { useGetReviewGradesMap } from '@src/utils/grades'
import {
  getGradesWithExpectations,
  getViewGradesWithExpectations,
} from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'

export const ProbationDeliverablesCard = connect(({ onHelpClick }: CommonCardProps) => {
  const { values, errors } = useLapeContext<ReviewScorecardInterface>()
  const hasKPI = !!values.review_data.kpis_section
  const ref = useRef<HTMLDivElement>(null)
  const gradesMap = useGetReviewGradesMap()

  useEffect(() => {
    if (
      !values.review_data.kpis_section?.recommended_rating &&
      values.review_data.kpis_section?.rating
    ) {
      set(
        values,
        'review_data.kpis_section.recommended_rating',
        getRoundedRating(values.review_data.kpis_section?.rating),
      )
    }
  }, [values.review_data.kpis_section])

  useEffect(() => {
    if (
      errors.review_data?.calculated_deliverables_ratings ||
      errors.review_data?.kpis_section
    ) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [
    errors.review_data?.calculated_deliverables_ratings,
    errors.review_data?.kpis_section,
  ])

  const kpiGrades = getKpiGrades(gradesMap, true)
  const kpiGradesWithExpectations = getGradesWithExpectations(kpiGrades, null)

  const onSelectGrade = (grade: CommonGradeOption) => {
    set(
      values,
      `review_data.calculated_deliverables_ratings.recommended_rating`,
      grade.key,
    )
  }

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => (
            <KPI reviewData={values.review_data} kpiGrades={kpiGradesWithExpectations} />
          )}
          type={CardContentTypes.KPI}
          title="Goals and deliverables"
          icon="Target"
          fields={[
            {
              field: 'review_data.kpis_section',
              title: 'Contribution and impact',
              grades: kpiGradesWithExpectations,
              gradeRecommendation: null,
            },
          ]}
          onSelectDeliverableGrade={grade => {
            set(values, `review_data.kpis_section.recommended_rating`, grade.key)
          }}
          isGradeSelectedRule={(field, grade) => {
            const ratingValue = get(values, field)?.recommended_rating
            return !!ratingValue && ratingValue === grade.key
          }}
          headerRef={ref}
          onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={() => (
            <ProbationDeliverables
              reviewData={values.review_data}
              category={values.category}
              cycleId={values.performance_checkpoint?.cycle_id}
              checkpointNum={values.probation_checkpoint?.number}
            />
          )}
          type={CardContentTypes.DELIVERABLES}
          title="Goals and deliverables"
          icon="Target"
          fields={[
            {
              field: 'review_data.calculated_deliverables_ratings',
              title: 'Contribution and impact',
              grades: getViewGradesWithExpectations(deliverablesRating),
              gradeRecommendation: null,
            },
          ]}
          onSelectDeliverableGrade={onSelectGrade}
          isGradeSelectedRule={(field, grade) => {
            const ratingValue = get(values, field)?.recommended_rating
            return !!ratingValue && ratingValue === grade.key
          }}
          headerRef={ref}
          onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
        />
      )}
    </>
  )
})
