import { api, apiWithoutHandling, handleError } from './index'
import { API } from '../constants/api'
import { RequestInterfaceNew, GetRequestInterface } from '../interfaces'
import { filterSortPageIntoQuery } from '../utils/table'
import {
  ReviewCategory,
  ReviewNextInterface,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
  NotCompletedReviewsInterface,
  ValueBasedCardInterface,
  PerformanceReviewInterface,
  PerformanceReviewStats,
  ReviewerRelation,
  ReviewDataInterface,
} from '@src/interfaces/performance'
import axios, { AxiosPromise, CancelTokenSource } from 'axios'
import { FilterByInterface, FetchDataQueryInterface } from '@src/interfaces/data'
import { getCleanValuesBeforeUpdate } from '@src/utils/performance'
import { useFetch } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const performanceReviewRequests: RequestInterfaceNew<ReviewScorecardInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
      {
        params: {
          category: ReviewCategory.Performance,
        },
      },
      'v2',
    )
  },
  update: async (data, { id }) => {
    const cleanData = getCleanValuesBeforeUpdate(data as ReviewScorecardInterface)

    return api.put(
      `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
        API.PERFORMANCE
      }/performanceReviews/${id}`,
      cleanData,
      {},
      'v2',
    )
  },
  submit: async data =>
    apiWithoutHandling.post(
      `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
      {
        category: ReviewCategory.Performance,
      },
      {},
      'v2',
    ),
}

export const performanceReviewLayoutRequests: RequestInterfaceNew<ReviewScorecardInterface> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
        {
          params: {
            category: ReviewCategory.Performance,
          },
        },
        'v2',
      )
    },
    update: async (data, { id }) => {
      const cleanData = getCleanValuesBeforeUpdate(data as ReviewScorecardInterface)

      return apiWithoutHandling.put(
        `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
          API.PERFORMANCE
        }/performanceReviews/${id}`,
        cleanData,
        {},
        'v2',
      )
    },
    submit: async data =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
        {
          category: ReviewCategory.Performance,
        },
        {},
        'v2',
      ),
  }

export const rejectReview = (employeeId: number | string, scorecardId: number | string) =>
  api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${scorecardId}/reject`,
    {},
    {},
    'v2',
  )

export const getCalculatedRating = (
  data: SkillCardInterface,
  employeeId: number,
  id: number,
  category: ReviewCategory,
  tokenSource?: CancelTokenSource,
) => {
  try {
    return apiWithoutHandling
      .post(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}/skillCardOverallRating`,
        data,
        {
          params: {
            category,
          },
          cancelToken: tokenSource?.token,
        },
        'v2',
      )
      .then(resp => resp.data.result)
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true }
    }

    handleError(error)
    return error
  }
}
export const getNextReviewData = (
  employeeId: number | string,
  id: number | string,
  category: 'performance' | 'probation',
): AxiosPromise<ReviewNextInterface> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}/performance/performanceReviews/${id}/next`,
    {
      params: {
        category,
      },
    },
    'v2',
  )

export const postPerformanceReviewForSummary = (
  data: ReviewScorecardInterface,
  category: ReviewCategory,
) =>
  api.post(
    `${API.EMPLOYEES}/${data.reviewed_employee.id}${API.PERFORMANCE}/performanceReviews/${data.id}/summaryRating`,
    data.review_data,
    {
      params: {
        category,
      },
    },
    'v2',
  )

export const useGetPerformanceReviewSummary = (
  cycleId?: string,
  employeeId?: number,
  category?: ReviewCategory | ReviewCycleCategory,
  filters?: FilterByInterface[],
  nominationId?: number | string,
) => {
  return useFetch<ReviewSummaryInterface>(
    employeeId === undefined || cycleId === undefined || !category
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/performanceReviews/summary`,
    'v2',
    {
      params: {
        ...filterSortPageIntoQuery(undefined, filters),
        category,
        nomination_id: nominationId,
      },
    },
  )
}

export const getPerformanceReviewSummary = (
  cycleId: string,
  employeeId: number,
  category: ReviewCategory,
  filters?: FilterByInterface[],
): AxiosPromise<ReviewSummaryInterface> => {
  return api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/performanceReviews/summary`,
    {
      params: {
        ...filterSortPageIntoQuery(undefined, filters),
        category,
      },
    },
    'v2',
  )
}

export const upwardsReviewRequests: RequestInterfaceNew<ReviewScorecardInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
      {
        params: {
          category: ReviewCategory.Upwards,
        },
      },
      'v2',
    )
  },
  update: async (data, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
        API.PERFORMANCE
      }/performanceReviews/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async data =>
    api.post(
      `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
      {
        category: ReviewCategory.Upwards,
      },
      {},
      'v2',
    ),
}

export const upwardsReviewLayoutRequests: RequestInterfaceNew<ReviewScorecardInterface> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
        {
          params: {
            category: ReviewCategory.Upwards,
          },
        },
        'v2',
      )
    },
    update: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
          API.PERFORMANCE
        }/performanceReviews/${id}`,
        data,
        {},
        'v2',
      ),
    submit: async data =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
        {
          category: ReviewCategory.Upwards,
        },
        {},
        'v2',
      ),
  }

export const getScorecardCommentsApi = (scorecardId: number) =>
  getCommentsAPI({
    baseUrl: `${API.PIP}/scorecards/${scorecardId}/comments`,
    apiVersion: 'v2',
  })

export const useGetNotCompletedReviews = (employeeId?: number) => {
  return useFetch<NotCompletedReviewsInterface>(
    employeeId
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/notComplete`
      : null,
    'v2',
  )
}

export const useGetPerformanceSkillSummary = (
  category?: ReviewCategory,
  cycleId?: number | string,
  employeeId?: number,
  skillId?: number,
) => {
  return useFetch<{ cards: SkillCardInterface[] | ValueBasedCardInterface[] }>(
    !category || !cycleId || !employeeId || !skillId
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/performanceReviews/skillSummary`,
    'v2',
    {
      params: {
        category,
        skill_id: skillId,
      },
    },
  )
}

export const putFirstOpenedScorecard = (
  employeeId: string,
  reviewId: string,
  category: ReviewCategory,
) =>
  api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${reviewId}/open?category=${category}`,
    undefined,
    undefined,
    'v2',
  )

export const getAllPerformanceReviews = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<PerformanceReviewInterface>
> =>
  api.get(
    `${API.PERFORMANCE}/performanceReviews`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getAllPerformanceReviewsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<PerformanceReviewStats>(
    `${API.PERFORMANCE}/performanceReviews/stats`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )
export const useGetPerformancePreview = (filter?: {
  employee: string
  specialisation: string
  seniority: string
  category: 'performance' | 'upwards'
  reviewed_employee_type:
    | 'high_impact_individual'
    | 'individual_contributor'
    | 'personal_kpi_individual'
  reviewer_relation: ReviewerRelation
}) => {
  return useFetch<ReviewDataInterface>({
    url: filter ? `/scorecardPreview?${new URLSearchParams(filter).toString()}` : null,
    version: 'v2',
  })
}
