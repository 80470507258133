import {
  Badge,
  Box,
  FilterButton,
  Flex,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  CardContentTypes,
  cardHasMissingValues,
  getRoundedRating,
  CommonGradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, isEmpty, lowerCase } from 'lodash'
import {
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import React, { useEffect, useRef, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import styled from 'styled-components'

const CardItemContainer = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  max-height: 62px;
  &:hover {
    background-color: ${Token.color.blue_5};
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: ${props => props.theme.zIndex.main};
  min-width: 13px;
  height: 13px;
  padding: 0;
  border: 2px solid ${Token.color.background};
  box-sizing: content-box;
`

interface CardItemProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
  field: CardField
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
  isGradeSelectedRule?: (field: string, grade: CommonGradeOption) => boolean
  onGradeClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: CommonGradeOption,
  ) => void
}

interface FilterButtonWithTooltipProps {
  grade: CommonGradeOption
  onGradeClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: CommonGradeOption,
  ) => void
  field: CardField
  selected: boolean
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
}

const getLoweredType = (type: CardContentTypes | string) => {
  return lowerCase(type).replace(' ', '-')
}

const FilterButtonWithTooltip = ({
  grade,
  onGradeClick,
  field,
  selected,
  isViewMode,
  type,
  cardItemIndex,
}: FilterButtonWithTooltipProps) => {
  const tooltip = useTooltip()
  const { aboveExp, belowExp } = grade
  const differFromExp = aboveExp || belowExp

  return (
    <Flex
      key={grade.key}
      justifyContent="center"
      style={{ position: 'relative' }}
      {...tooltip.getAnchorProps()}
    >
      {differFromExp && selected && (
        <StyledBadge
          useIcon={aboveExp ? 'ArrowUp' : 'ArrowDown'}
          backgroundColor={aboveExp ? Token.color.green : Token.color.orange}
        />
      )}
      <FilterButton
        onClick={e => onGradeClick(e, field, grade)}
        active={selected}
        style={{ height: '30px' }}
        px="s-12"
        disabled={isViewMode && !selected}
        data-testid={`card-${getLoweredType(type)}-${cardItemIndex}-${getLoweredType(
          grade.text,
        )}${selected ? '-active' : ''}`}
      >
        <Text data-testid="button-text">{grade.text}</Text>
      </FilterButton>
      {(grade.tooltip || differFromExp) && (
        <Tooltip {...tooltip.getTargetProps()} placement="top-end" maxWidth={300}>
          <VStack>
            {differFromExp && (
              <Text color={aboveExp ? Token.color.green : Token.color.orange}>
                {aboveExp ? 'Above' : 'Below'} expectations
              </Text>
            )}
            {grade.tooltip}
          </VStack>
        </Tooltip>
      )}
    </Flex>
  )
}

export const CardItem = ({
  data,
  field,
  isViewMode,
  type,
  cardItemIndex,
  isGradeSelectedRule,
  onGradeClick,
  renderExpandedContent,
  renderExceedingContent,
}: CardItemProps) => {
  const { errors } = useLapeContext<ReviewScorecardInterface>()
  const [expanded, setExpanded] = useState(false)
  const cardItemRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (e: Event) => {
    if (!cardItemRef?.current?.contains(e.target as Node)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    if (expanded) {
      window.addEventListener('click', handleClickOutside)
    } else {
      window.removeEventListener('click', handleClickOutside)
    }

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [expanded, cardItemRef.current])

  const fieldValue = get(data, field.field)
  const hasMissingValues = cardHasMissingValues(
    type,
    fieldValue as SkillCardInterface,
    errors,
  )
  const hasErrors = !isEmpty(get(errors, field.field))
  const validationFailed = hasErrors && hasMissingValues

  const selectedRating = fieldValue?.recommended_rating || fieldValue?.rating || null
  const roundedRating = getRoundedRating(selectedRating)
  const selectedGrade = field.grades.find(grade => grade.key === roundedRating)
  const differFromExpectation = selectedGrade?.aboveExp || selectedGrade?.belowExp

  const renderExceeding = !expanded && differFromExpectation && !!renderExceedingContent

  return (
    <VStack ref={cardItemRef}>
      <CardItemContainer
        justifyContent="space-between"
        alignItems="center"
        m="s-4"
        py="s-20"
        px="s-16"
        bg={validationFailed ? Token.color.inputError : undefined}
        borderRadius={Token.radius.r12}
        onClick={() => {
          setExpanded(!expanded)
        }}
        data-testid={`card-item-${getLoweredType(type)}-${cardItemIndex}`}
      >
        <Text variant="primary" fontWeight={600}>
          {field.title}
        </Text>
        <Flex alignItems="center" justifyContent="flex-end">
          {!expanded && field.grades[0]?.text && (
            <Flex gap="s-4" alignItems="center">
              {field.grades.map(grade => {
                const selected = isGradeSelectedRule
                  ? isGradeSelectedRule(field.field, grade)
                  : grade?.key === getRoundedRating(fieldValue)
                return (
                  <FilterButtonWithTooltip
                    key={grade.key}
                    grade={grade}
                    onGradeClick={onGradeClick}
                    field={field}
                    selected={selected}
                    isViewMode={isViewMode}
                    type={type}
                    cardItemIndex={cardItemIndex}
                  />
                )
              })}
            </Flex>
          )}
          <Box ml="s-16" data-testid="card-button">
            <Icon
              name={expanded ? 'ChevronUp' : 'ChevronDown'}
              size={20}
              color={Token.color.greyTone20}
            />
          </Box>
        </Flex>
      </CardItemContainer>
      {expanded && (
        <Box m="s-16" mt={0}>
          {renderExpandedContent({
            type,
            field,
            cardIndex: cardItemIndex,
          })}
        </Box>
      )}
      {renderExceeding && (
        <Box m="s-16" mt={0}>
          {renderExceedingContent?.({
            type,
            field,
            cardIndex: cardItemIndex,
          })}
        </Box>
      )}
    </VStack>
  )
}
