import React, { ReactNode } from 'react'
import { Box, TableWidget } from '@revolut/ui-kit'

export const TableWithForm = ({
  form,
  actions,
  table,
}: {
  form: ReactNode
  actions?: ReactNode
  table?: ReactNode
}) => {
  return (
    <TableWidget>
      <Box m="-s-16" mb={actions || table ? 's-16' : undefined}>
        {form}
      </Box>
      {actions && <TableWidget.Actions>{actions}</TableWidget.Actions>}
      {table && <TableWidget.Table>{table}</TableWidget.Table>}
    </TableWidget>
  )
}
