import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import {
  onboardingBulkSessionDocumentsAssigneeColumn,
  onboardingBulkSessionDocumentsCategoryColumn,
  onboardingBulkSessionDocumentsNameColumn,
  onboardingDocumentsExistingItemsAssigneeColumn,
  onboardingDocumentsExistingItemsCategoryColumn,
  onboardingDocumentsExistingItemsNameColumn,
} from '@src/constants/columns/onboardingDocumentsV2'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeeDocumentInterface } from '@src/interfaces/documents'

export const existingItemsRow = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<EmployeeDocumentInterface>> => ({
  cells: [
    {
      ...onboardingDocumentsExistingItemsNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingDocumentsExistingItemsCategoryColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingDocumentsExistingItemsAssigneeColumn(options.onChange),
      width: 200,
    },
  ],
})

export const bulkSessionRow = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportDocumentsItemInterface>> => ({
  cells: [
    {
      ...onboardingBulkSessionDocumentsNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingBulkSessionDocumentsCategoryColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingBulkSessionDocumentsAssigneeColumn(options.onChange),
      width: 200,
    },
  ],
})
