import { GlobalSearchTypes } from '@src/interfaces/globalSearch'
import { APPLICATIONS } from '@src/constants/hub'

export const getFavouriteTypeIconProps = (type: GlobalSearchTypes): [string, string] => {
  switch (type) {
    case 'team':
    case 'department':
    case 'company':
      return [APPLICATIONS.teams.image, APPLICATIONS.teams.bg]
    case 'function':
    case 'role':
    case 'specialisation':
      return [APPLICATIONS.positions.image, APPLICATIONS.positions.bg]
    case 'skill':
      return [APPLICATIONS.skills.image, APPLICATIONS.skills.bg]
    case 'team_kpi':
    case 'department_kpi':
    case 'employee_kpi':
    case 'company_kpi':
    case 'company_goal':
      return [APPLICATIONS.kpis.image, APPLICATIONS.kpis.bg]
    default:
      return ['', 'deep-grey']
  }
}
