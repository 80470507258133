import React from 'react'
import {
  Ratings,
  ReviewDataInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { Box, chain, Flex, InputGroup, Text, Token, VStack } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import get from 'lodash/get'
import {
  CommonSectionProps,
  getJustificationMessage,
  getRoundedRating,
  kpiRating,
  DeliverableGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { relationToString } from '@src/features/Scorecard/constants'
import BottomText from '@components/Inputs/partials/BottomText'

interface KpiProps extends CommonSectionProps {
  kpiGrades: DeliverableGradeOption[]
  justificationOnly?: boolean
  showJustificationError?: boolean
  onSelectGrade?: (
    reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
    grade: DeliverableGradeOption,
  ) => void
}

export const KPI = connect(
  ({
    reviewData,
    isViewMode = false,
    kpiGrades,
    justificationOnly = false,
    showJustificationError,
    onSelectGrade,
  }: KpiProps) => {
    if (!reviewData) {
      return null
    }
    const getFieldDetails = (option: DeliverableGradeOption) => {
      const ratingValue = get(reviewData, 'kpis_section.recommended_rating')
      const isChecked =
        kpiRating.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }

    const getViewFieldDetails = (option: DeliverableGradeOption) => {
      const ratings =
        get(reviewData, 'kpis_section.recommended_ratings')?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    const selectedRating = reviewData.kpis_section?.recommended_rating
    const selectedGrade = kpiGrades.find(item => item.key === selectedRating)
    const justificationMessage = getJustificationMessage(false, selectedGrade)

    return (
      <VStack width="100%" space="s-16">
        {!justificationOnly && (
          <Flex
            backgroundColor={Token.color.greyTone2}
            borderRadius={Token.radius.r16}
            style={{ overflow: 'hidden' }}
          >
            <InputGroup style={{ width: '100%', gap: 0 }}>
              {kpiGrades.map((option, optionIdx) => {
                const { isChecked, ratings } = isViewMode
                  ? getViewFieldDetails(option)
                  : getFieldDetails(option)
                return (
                  <DeliverablesGrade
                    key={optionIdx}
                    isViewMode={isViewMode}
                    isChecked={isChecked}
                    ratings={ratings}
                    option={option}
                    onChange={() => onSelectGrade?.(reviewData, option)}
                  />
                )
              })}
            </InputGroup>
          </Flex>
        )}
        {isViewMode ? (
          <>
            {!!reviewData?.kpis_section?.comments?.length && (
              <VStack space="s-8">
                {reviewData.kpis_section.comments.map(comment => (
                  <Box
                    p="s-16"
                    key={comment.review.id}
                    border={`1px solid ${Token.color.greyTone10}`}
                    borderRadius={Token.radius.r16}
                  >
                    <VStack space="s-8">
                      <Text variant="primary">
                        {chain(
                          comment.review.reviewer.full_name,
                          relationToString(comment.review.reviewer_relation, true),
                        )}
                      </Text>
                      <Text variant="secondary">{comment.value}</Text>
                    </VStack>
                  </Box>
                ))}
              </VStack>
            )}
          </>
        ) : (
          <>
            <LapeNewTextArea
              rows={2}
              label={justificationMessage}
              name="review_data.kpis_section.comment"
              data-testid="comment"
              hideOptional
              hasError={showJustificationError}
            />
            {showJustificationError && <BottomText error="Please add justification" />}
          </>
        )}
      </VStack>
    )
  },
)
